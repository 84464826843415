<template>
  <div class="page page__search search" ref="search">
    <esmp-loader v-if="loading" fix />
    <div v-if="isServicesOrFaqListShowed" class="search__list">
      <esmp-tabs :value="activeSearchTypeCode" :animated="false">
        <esmp-tab-pane
          label="Услуги"
          name="services"
          :counter="isServicesNotEmpty ? services.length : 0"
        >
          <template v-if="isServicesNotEmpty">
            <service-list-item
              v-for="service in paginatedServices"
              :key="service.id"
              :service="service"
              @go-to-create-ticket="onGoToCreateTicket"
            />

            <div class="search__pagination">
              <esmp-pagination-adaptive
                :page-count="servicesPagesCount"
                :current-page.sync="servicesCurrentPage"
                @change="scrollToSearchTop"
              />
            </div>
          </template>

          <div v-else class="search__empty">
            По запросу <b>"{{ query }}"</b> услуг не найдено
          </div>
        </esmp-tab-pane>

        <esmp-tab-pane
          label="База знаний"
          name="faq"
          :counter="isFaqArticlesNotEmpty ? faqArticles.length : 0"
        >
          <template v-if="isFaqArticlesNotEmpty">
            <search-faq-items :items="paginatedFaqArticles" />

            <div class="search__pagination">
              <esmp-pagination-adaptive
                :page-count="faqArticlesPagesCount"
                :current-page.sync="faqArticlesCurrentPage"
                @change="scrollToSearchTop"
              />
            </div>
          </template>

          <div v-else class="search__empty">
            По запросу <b>"{{ query }}"</b> статей в базе знаний не найдено
          </div>
        </esmp-tab-pane>
      </esmp-tabs>
    </div>

    <template v-if="type === 2">
      <div class="search__ticket-toolbar">
        <esmp-button
          view="function"
          icon="filter"
          icon-position="left"
          class="search__filter"
          @click="showModal('filter-ticket')"
        >
          Фильтр
        </esmp-button>
      </div>
      <filter-ticket
        :is-showed-filter-ticket="isShowedFilterTicket"
        @hide-modal="hideModal"
      />
    </template>
    <template v-if="isTicketsListShowed">
      <div class="search__list">
        <div class="search__ticket-table">
          <div class="search__ticket-head">
            <div class="search__ticket-col-name">
              Название, номер, дата создания
            </div>

            <div class="search__ticket-col-name">
              Тема и описание
            </div>

            <div class="search__ticket-col-name">
              Дата выполнения
            </div>

            <div class="search__ticket-col-name">
              Статус
            </div>

            <div class="search__ticket-col-name">
              Клиент
            </div>
          </div>

          <search-ticket
            v-for="ticket in paginatedTickets"
            :key="ticket.id"
            :ticket="ticket"
          />

          <div class="search__pagination">
            <esmp-pagination-adaptive
              :page-count="ticketsPagesCount"
              :current-page.sync="ticketsCurrentPage"
              @change="scrollToSearchTop"
            />
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="isEmptyMessageShowed"
      class="search__empty"
    >
      Ничего не найдено
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getPagesCount, getPaginatedArray } from '@/utils/pagination';
import { SEARCH_RESULTS_PER_PAGE } from '@/constants/search';

const ServiceListItem = () => import('@/components/service-list-item');
const SearchTicket = () => import('@/components/search-lists/search-ticket');
const SearchFaqItems = () => import('@/components/search-lists/search-faq-items');
const FilterTicket = () => import('@/components/filter-ticket');

export default {
  name: 'Search',

  components: {
    SearchFaqItems,
    SearchTicket,
    FilterTicket,
    ServiceListItem,
  },

  data() {
    return {
      isShowedFilterTicket: false,
      servicesCurrentPage: 1,
      ticketsCurrentPage: 1,
      faqArticlesCurrentPage: 1,
    };
  },

  computed: {
    ...mapState('search', ['services', 'faqArticles', 'type', 'loading', 'query', 'searchLogId']),
    ...mapGetters('search', ['filteredTickets', 'activeSearchTypeCode']),

    isServicesNotEmpty() {
      return !!this.services?.length;
    },

    isFaqArticlesNotEmpty() {
      return !!this.faqArticles?.length;
    },

    isServicesOrFaqListShowed() {
      return this.isServicesNotEmpty || this.isFaqArticlesNotEmpty;
    },

    isTicketsListShowed() {
      return !!this.filteredTickets?.length;
    },

    isEmptyMessageShowed() {
      return !this.loading && !this.isServicesOrFaqListShowed && !this.isTicketsListShowed;
    },

    paginatedTickets() {
      return this.filteredTickets
        ? getPaginatedArray(this.filteredTickets, this.ticketsCurrentPage, SEARCH_RESULTS_PER_PAGE)
        : [];
    },

    ticketsPagesCount() {
      return this.filteredTickets
        ? getPagesCount(this.filteredTickets.length, SEARCH_RESULTS_PER_PAGE)
        : 0;
    },

    paginatedServices() {
      return this.services
        ? getPaginatedArray(this.services, this.servicesCurrentPage, SEARCH_RESULTS_PER_PAGE)
        : [];
    },

    servicesPagesCount() {
      return this.services
        ? getPagesCount(this.services.length, SEARCH_RESULTS_PER_PAGE)
        : 0;
    },

    paginatedFaqArticles() {
      return this.faqArticles
        ? getPaginatedArray(this.faqArticles, this.faqArticlesCurrentPage, SEARCH_RESULTS_PER_PAGE)
        : [];
    },

    faqArticlesPagesCount() {
      return this.faqArticles
        ? getPagesCount(this.faqArticles.length, SEARCH_RESULTS_PER_PAGE)
        : 0;
    },
  },

  watch: {
    type() {
      this.reset();
    },
    filteredTickets() {
      this.ticketsCurrentPage = 1;
    },
  },

  beforeRouteLeave(to, form, next) {
    if (to.name !== 'CreateTicket') {
      this.clearSearchLogId();
    }
    next();
  },

  destroyed() {
    this.clearSearchDataAndQuery();
  },

  methods: {
    ...mapActions('search', ['clearSearchDataAndQuery', 'clearSearchData', 'clearSearchLogId']),

    hideModal(payload) {
      if (payload.modalName === 'filter-ticket') {
        this.isShowedFilterTicket = payload.newValue;
      }
    },

    showModal(modalName) {
      if (modalName === 'filter-ticket') {
        this.isShowedFilterTicket = true;
      }
    },

    onGoToCreateTicket({ serviceId, sourceSystem }) {
      if (this.searchLogId) {
        const params = {
          searchLogId: this.searchLogId,
          serviceId,
          sourceSystem,
        };

        this.$API.log.sendSearchLogIdOnCreateTicket(params);
      }
    },

    reset() {
      this.ticketsCurrentPage = 1;
      this.servicesCurrentPage = 1;
      this.faqArticlesCurrentPage = 1;
      this.clearSearchData();
      this.clearSearchLogId();
    },
    scrollToSearchTop() {
      this.$nextTick(() => {
        this.$refs.search.scrollIntoView({ behavior: 'smooth' });
      });
    },
  },
};
</script>

<style lang="scss">
.search {
  $root: &;

  &__item {
    padding: 24px;
    background: $color-white;
    box-shadow: $base-shadow;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &--favorites {
      #{$root}__item-actions {
        flex-shrink: 0;

        .esmp-button {
          color: $color-service-manager-logo;
        }
      }
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__description {
    color: $color-black-op-50;
    margin-bottom: 16px;
  }

  &__item-content {
    margin-right: 20px;
    max-width: 584px;
  }

  &__item-actions {
    flex-shrink: 0;

    .esmp-button {
      color: $color-black;
    }
  }

  &__ticket-toolbar {
    display: flex;
    justify-content: flex-end;

    @include for-size(phone-landscape-down) {
      margin-bottom: 6px;
    }
  }

  &__filter {
    color: $color-black;
    @include hover(true) {
      color: $primary-color-hover;
    }
  }

  &__ticket-head {
    display: flex;
    padding: 0 20px 8px;
    align-items: center;

    @include for-size(phone-landscape-down) {
      align-items: unset;
    }
  }

  &__ticket-col-name {
    font-size: 14px;
    line-height: 20px;
    color: $color-black-op-50;
    &:first-child {
      width: 25%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 10%;
      text-align: center;
    }
    &:nth-child(4) {
      width: 22%;
      text-align: center;
    }
    &:nth-child(5) {
      width: 18%;
      text-align: center;
    }

    @include for-size(phone-landscape-down) {
      font-size: 12px;
      line-height: 16px;
    }

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }

  &__pagination {
    padding-top: $base-gutter;
    display: flex;
    justify-content: center;
  }
}
</style>
